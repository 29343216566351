import { DebtorSearchResponse, SingleDebtorSearchResult } from './types';
import Loader from '../../common/components/loader';
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as SadFaceSVG } from '../../assets/icons/sad_face.svg';
import SearchResultsHeading from './details/SearchResultsHeading';
import { StyledTable } from '../start-ucc-filing/styled-items/StyledTable';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useRef, useState } from 'react';
import { uccSearchDetailsPageURL } from '../../common/constants/routes';
import Navigation from './details/Navigation';
import { gridActiveRowColor, gridLoadingBackdrop } from '../../common/constants/colors';
import { useSearchPageQueryParams } from './useSearchPageQueryParams';
import { StyledTableContainer } from './details/StyledComponents';
import { useReactToPrint } from 'react-to-print';
import logo from '../../assets/icons/ucclogo.png';
import { StyledButton } from '../../common/components/logged-in-user-menu/StyledButton';

interface Props extends Pick<ReturnType<typeof useSearchPageQueryParams>, 'getUrl'> {
  isFetching: boolean;
  nextUrl: string | null;
  prevUrl: string | null;
  results?: DebtorSearchResponse;
  lastSearchedKeyword: string;
  handlePrint: () => void;
}

const columnNames = ['Name', 'UCC Number', 'Address', 'City', 'State', 'Zip Code', 'Status'];

export const SearchResults = ({
  lastSearchedKeyword,
  isFetching,
  results,
  nextUrl,
  prevUrl,
  getUrl,
  handlePrint,
}: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const hasDebtorsDisplayed = Boolean(results?.debtors?.length);
  const [activeRow, setActiveRow] = useState<SingleDebtorSearchResult | null>(null);

  const goToDetails = useCallback(() => {
    if (!activeRow) return;

    navigate(
      getUrl(uccSearchDetailsPageURL, {
        rn: activeRow.rowNumber.toString(),
      })
    );
  }, [activeRow, getUrl, navigate]);

  if (isFetching && !hasDebtorsDisplayed)
    return (
      <Box display={'flex'} justifyContent={'center'} minHeight={200} alignItems={'center'}>
        <Loader color="blue" size={40} />
      </Box>
    );

  if (!results) return null;

  if (!hasDebtorsDisplayed) {
    return (
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={theme.typography.pxToRem(12)}
        marginTop={theme.typography.pxToRem(30)}
      >
        <SadFaceSVG />
        <Typography variant={'h4'}>
          {`We couldn't find a match for “${lastSearchedKeyword}”. Please try another search.`}
        </Typography>
      </Box>
    );
  }

  return (
    <Box position={'relative'}>
      {isFetching && (
        <Box
          zIndex={1}
          width={'100%'}
          height={'100%'}
          display={'flex'}
          position={'absolute'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ backgroundColor: gridLoadingBackdrop }}
        >
          <Loader color="blue" size={50} />
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <StyledButton variant="text" sx={{ padding: 0 }} onClick={handlePrint}>
          Print Page
        </StyledButton>
      </Box>

      <StyledTableContainer variant="outlined" component={Paper}>
        <SearchResultsHeading content={'Search Results'} />
        <StyledTable style={{ minWidth: '1050px' }} disableFilter>
          <TableHead>
            <TableRow>
              {columnNames.map((name) => (
                <TableCell sx={{ whiteSpace: 'nowrap' }} key={name}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(results.debtors ?? []).map((debtor) => {
              return (
                <TableRow
                  tabIndex={0}
                  onClick={goToDetails}
                  key={debtor.rowNumber}
                  onFocus={() => setActiveRow(debtor)}
                  onMouseEnter={() => setActiveRow(debtor)}
                  onKeyUp={(e) => {
                    e.key === 'Enter' && goToDetails();
                  }}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: debtor === activeRow ? gridActiveRowColor : 'white',
                  }}
                >
                  <TableCell>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>{debtor.name}</Typography>
                  </TableCell>
                  <TableCell>{debtor.uccNumber?.trim() || 'N/A'}</TableCell>
                  <TableCell>{debtor.address?.trim() || 'N/A'}</TableCell>
                  <TableCell>{debtor.city?.trim() || 'N/A'}</TableCell>
                  <TableCell>{debtor.state?.trim() || 'N/A'}</TableCell>
                  <TableCell>{debtor.zipCode?.trim() || 'N/A'}</TableCell>
                  <TableCell style={{ fontWeight: 600, textTransform: 'uppercase' }}>
                    {debtor.status?.trim() || 'N/A'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      <Navigation prevUrl={prevUrl} nextUrl={nextUrl} />
    </Box>
  );
};
